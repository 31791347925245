export const accesses = {
  headset: 'Auricular',
  intravenous: 'Endovenoso',
  inhalation: 'Inalatório',
  Intraarticular: 'Intra-articular',
  intramuscular: 'Intramuscular',
  nasal: 'Nasal',
  eyepiece: 'Ocular',
  oral: 'Oral',
  rectal: 'Retal',
  subcutaneous: 'Subcutâneo',
  sublingual: 'Sublingual',
  topic: 'Tópico',
  transdermal: 'Transdérmico',
  vaginal: 'Vaginal',
};

export function getName(value) {
  return accesses[value];
}
