<template>
  <div class="page-container billing-accounts-page">
    <portal to="page-name">Atendimentos</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Atendimentos</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-4 col-md-12 col-sm-12 form-group">
            <label for="filter-covenant" class="form-label">Convênio</label>
            <select id="filter-covenant" class="form-select"
                    v-model="filters.insurancePlanId">
              <option value="">Todos os convênios</option>
              <option v-for="(item, i) in insurances"
                      :value="item.plan.id" :key="i">{{ item.customName }}</option>
            </select>
          </div>

          <div class="column col-2 col-md-6 col-sm-12 form-group"
               :class="{'has-error': $v.filters.startDate.$error}">
            <label class="form-label">Início</label>
            <dx-input-date type="text" id="filter-start-date"
                           name="filter-start-date"
                           @blur="$v.filters.startDate.$touch()"
                           v-model="filters.startDate" class="form-input"
                           placeholder="00/00/0000" autocomplete="nope" />
          </div>

          <div class="column col-2 col-md-6 col-sm-12 form-group"
               :class="{'has-error': $v.filters.endDate.$error}">
            <label class="form-label">Término</label>
            <dx-input-date type="text" id="filter-end-date"
                           name="filter-end-date"
                           @blur="$v.filters.endDate.$touch()"
                           v-model="filters.endDate" class="form-input"
                           placeholder="00/00/0000" autocomplete="nope" />
          </div>

          <div class="column col-4 col-md-6 col-sm-12 form-group">
            <label for="filter-status" class="form-label">Faturamento</label>
            <select id="filter-status" class="form-select"
                    v-model="filters.status">
              <option value="">Todos os status</option>
              <option value="no_account">Atendimentos não faturados</option>
              <option value="account">Atendimentos faturados</option>
            </select>
          </div>

          <div class="column col-3 col-md-6 col-sm-12 form-group">
            <label class="form-label">Tipo do procedimento</label>
            <select id="filter-schedule-types" class="form-select"
                    v-model="filters.scheduleType">
              <option value="">Todos os tipos</option>
              <option v-for="(text, value) in types" :value="value" :key="value">
                {{ text }}
              </option>
            </select>
          </div>

          <div class="column col-2 col-md-6 col-sm-12 form-group">
            <label class="form-label">Local</label>
            <select class="form-select" v-model="filters.placeId">
              <option value="">Todos os locais</option>
              <option
                v-for="place in places"
                :value="place.id"
                :key="place.id"
              >{{ place.name }}</option>
            </select>
          </div>

          <div class="column col-3 col-md-6 col-sm-12 form-group">
            <label for="filter-professional" class="form-label">Profissional</label>
            <select id="filter-professional" class="form-select"
                    v-model="filters.professionalId">
              <option value="">Todos os profissionais</option>
              <option v-for="(professional, i) in professionals" :key="i"
                      :value="professional.id">{{ professional.name }}</option>
            </select>
          </div>

          <div class="column col-4 col-md-6 col-sm-12 form-group">
            <label class="form-label" for="filter-patient">Paciente</label>
            <input type="text" id="filter-patient" name="filter-patient"
                   v-model="filters.patient" class="form-input" />
          </div>

          <div class="column col-12 form-group">
            <label class="form-label"></label>
            <div class="form-group">
              <label class="form-checkbox">Status do agendamento
                <input type="checkbox"
                       @click="selectAllStatuses(
                               statusSelectedAll === null
                               ? true : !statusSelectedAll)"
                       :checked="statusSelectedAll"
                       :indeterminate.prop="statusSelectedAll === null">
                <i class="form-icon"/>
              </label>
            </div>
            <div class="status-group">
              <div class="columns">
                <div class="column col-2 col-md-3 col-sm-12"
                     v-for="(item, i) in statuses" :key="i">
                  <label class="form-checkbox">
                    <input type="checkbox" v-model="item.selected" />
                    <i class="form-icon"></i> {{ item.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="column col-auto form-group mb-2"
               style="align-self: flex-end">
            <button class="btn btn-primary btn-icon-left mr-1"
                    @click="load">
              <fa-icon class="icon" :icon="['fal', 'search']"></fa-icon>
              Atualizar
            </button>
            <button class="btn btn-gray btn-icon btn-icon-left mr-1"
                    :class="{loading: printing}"
                    :disabled="printing"
                    @click="print('sheet')">
              <fa-icon :icon="['fal', 'file-excel']"/>Gerar planilha
            </button>
            <button class="btn btn-gray btn-icon-left"
                    :class="{loading: printing}"
                    @click="print('pdf')" :disabled="printing">
              <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
            </button>
          </div>
        </div>
        <label class="form-switch form-group"
               v-if="filters.professionalId && filters.status === 'account'">
          <input type="checkbox" v-model="filters.otherProfessional"/>
          <i class="form-icon"/>Atendimento faturado para outro profissional
        </label>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Atendimentos</p>
              <p class="empty-subtitle">
                Nenhum atendimento encontrado. Verifique os filtros para realizar novas buscas
              </p>
            </div>
          </template>
          <template v-else>
            <div class="attendance-summary">
              <div class="stenci-title mb-2">Resumo</div>
              <div class="columns">
                <div class="column col-3 form-group">
                  Agendamentos: <span class="text-bold">{{ data.appointments }}</span>
                </div>
                <div class="column col-3 form-group">
                  Procedimentos: <span class="text-bold">{{ data.expenses }}</span>
                </div>
                <div class="column col-3 form-group" v-if="!filters.status">
                  Cancelamentos: <span class="text-bold">{{ data.canceled }}</span>
                </div>
                <div class="column col-3 form-group" v-if="!filters.status">
                  Faltas: <span class="text-bold">{{ data.missed }}</span>
                </div>
                <div class="column col-3 form-group" v-if="!filters.status">
                  Finalizados: <span class="text-bold">{{ data.finished }}</span>
                </div>
                <div class="column col-3 form-group" v-if="!filters.status">
                  Contas: <span class="text-bold">{{ data.accounts }}</span>
                </div>
                <div class="column col-3 form-group">
                  Total recebido: <span class="text-bold">{{ total | currency }}</span>
                </div>
                <div class="column col-3 form-group">
                  Total líquido: <span class="text-bold">{{ netTotal | currency }}</span>
                </div>
              </div>
            </div>
            <div class="columns attendance-items-title">
              <div class="column col-auto">
                <span class="mr-2"></span>
              </div>
              <div class="column col-auto" style="width: 100px">
                <span class="hover-link" @click="orderByAccounts('date')">
                  <strong>Data</strong>
                  <fa-icon style="margin-left: 3px" :icon="['fal', getOrderByIcon('date')]">
                  </fa-icon>
                </span>
              </div>
              <div class="column">
                <span class="hover-link" @click="orderByAccounts('patient')">
                  <strong>Paciente</strong>
                  <fa-icon style="margin-left: 3px" :icon="['fal', getOrderByIcon('patient')]">
                  </fa-icon>
                </span>
              </div>
              <div class="column col-3 hide-sm">
                <span class="hover-link" @click="orderByAccounts('insurance')">
                  <strong>Convênio</strong>
                  <fa-icon style="margin-left: 3px" :icon="['fal', getOrderByIcon('insurance')]">
                  </fa-icon>
                </span>
              </div>
              <div class="column col-2 hide-md hide-sm">
                <strong class="hover-link">Procedimento</strong>
              </div>
              <div class="column col-2 col-sm-3 text-right">
                <span class="hover-link" @click="orderByAccounts('total')">
                  <strong>Total</strong>
                  <fa-icon style="margin-left: 3px" :icon="['fal', getOrderByIcon('total')]">
                  </fa-icon>
                </span>
              </div>
            </div>
            <div v-for="(item, i) in data.items" :key="i">
              <attendance-item :data="item" />
            </div>
          </template>
        </template>
      </div>
      <div class="card-body">
        <div class="attendance-summary" v-if="data.items.length > 0">
          <div class="stenci-title mb-2">Total Geral</div>
          <div class="columns">
            <div class="column col-2 form-group">
              Total bruto: <span class="text-bold">{{ grossTotal | currency }}</span>
            </div>
            <div class="column col-2 form-group">
              Total desconto: <span class="text-bold">{{ discountTotal | currency }}</span>
            </div>
            <div class="column col-2 form-group">
              Total juros: <span class="text-bold">{{ interestTotal | currency }}</span>
            </div>
            <div class="column col-2 form-group">
              Total recebido: <span class="text-bold">{{ total | currency }}</span>
            </div>
            <div class="column col-2 form-group">
              Total taxa <span class="text-bold">{{ rateTotal | currency }}</span>
            </div>
            <div class="column col-2 form-group">
              Total líquido: <span class="text-bold">{{ netTotal | currency }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { types } from 'src/data/appointment-types';
import { PRINT_ATTENDANCE_LIST } from '@/data/actions/modules/financial';
import * as professionalApi from '@/app/professional/api';
import { date, minDate } from '../../../../data/validators';
import formMixin from '../../../../mixins/form';
import AttendanceItem from './Item.vue';

export default {
  components: {
    AttendanceItem,
  },
  mixins: [formMixin],
  data() {
    return {
      types,
      statuses: [
        { id: 'scheduled', name: 'Agendado', selected: true },
        { id: 'confirmed', name: 'Confirmado', selected: true },
        { id: 'in_attendance', name: 'Em Atendimento', selected: true },
        { id: 'waiting', name: 'Em espera', selected: true },
        { id: 'missed', name: 'Falta', selected: false },
        { id: 'finished', name: 'Finalizado', selected: true },
        { id: 'report', name: 'Laudo', selected: true },
        { id: 'payment', name: 'Pagamento', selected: true },
        { id: 'rescheduled', name: 'Remarcado', selected: false },
        { id: 'screening', name: 'Triagem', selected: true },
      ],
      insurances: [],
      professionals: [],
      places: [],
      debounceTimeout: null,
      loading: false,
      printing: false,
      orderBy: {
        type: 'patient',
        index: 1,
      },
      filters: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        status: 'no_account',
        insurancePlanId: '',
        professionalId: '',
        patient: '',
        scheduleType: '',
        otherProfessional: false,
        appointmentStatus: '',
        placeId: '',
      },
      data: {
        items: [],
        accounts: 0,
        expenses: 0,
        appointments: 0,
        canceled: 0,
        missed: 0,
        finished: 0,
        limit: 0,
        offset: 0,
      },
    };
  },
  mounted() {
    this.loadInsurances();
    this.loadProfessionals();
    this.loadPlaces();
    this.load();
  },
  validations() {
    return {
      filters: {
        startDate: { date, required },
        endDate: { date, required },
      },
      invoice: {
        form: {
          deliveryDate: { required, date, minDate: minDate() },
          receiptDate: { required, date, minDate: minDate() },
          paymentDate: { required, date, minDate: minDate() },
        },
      },
    };
  },
  computed: {
    canAccessPrintAttendance() {
      if (PRINT_ATTENDANCE_LIST) {
        return this.$can(PRINT_ATTENDANCE_LIST);
      }
      return true;
    },
    total() {
      return this.data.items
        .reduce((a, b) => a + b.total, 0);
    },
    netTotal() {
      return this.data.items
        .reduce((a, b) => a + (b.values.net || 0), 0);
    },
    discountTotal() {
      return this.data.items
        .reduce((a, b) => a + (b.values.discount || 0), 0);
    },
    interestTotal() {
      return this.data.items
        .reduce((a, b) => a + (b.values.interest || 0), 0);
    },
    rateTotal() {
      return this.data.items
        .reduce((a, b) => a + (b.values.rate || 0), 0);
    },
    grossTotal() {
      return this.data.items
        .reduce((a, b) => a + (b.values.gross || 0), 0);
    },
    statusSelectedAll() {
      const { statuses } = this;

      const selected = statuses
        .filter(item => item.selected).length;

      if ((selected === statuses.length)
        && statuses.length > 0) {
        return true;
      } if (selected > 0) {
        return null;
      }
      return false;
    },
  },
  methods: {
    search() {
      this.data.offset = 0;
      this.load();
    },
    load() {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return;
      }

      const duration = moment.duration(moment(this.filters.endDate)
        .diff(moment(this.filters.startDate))).asMonths();

      if (duration > 3) {
        this.$toast.show('O intervalo entre as datas não pode ser superior a 3 meses!',
          { type: 'error' });
        return;
      }

      const params = this.getParams();
      if (params.statuses.length === 0) {
        this.$toast.show('Por favor, informe ao menos um status de agendamento', { type: 'error' });
        return;
      }

      this.loading = true;
      this.$httpX.get('/billing-attendances', { params })
        .then(({ data }) => {
          this.data = {
            items: data.items.map((item) => {
              // eslint-disable-next-line
              item.selected = false;
              return item;
            }),
            expenses: data.expenses,
            accounts: data.accounts,
            appointments: data.appointments,
            canceled: data.canceled,
            missed: data.missed,
            finished: data.finished,
            limit: data.limit,
            offset: data.offset,
          };
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    paginate({ offset }) {
      this.data.offset = offset;
      this.load();
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.apiGetInsurances(params)
        .then((data) => {
          this.insurances = data.items;
        })
        .catch(() => {});
    },
    loadProfessionals() {
      return professionalApi.allActive()
        .then((data) => {
          this.professionals = data.items;
        })
        .catch(this.$toast.error);
    },
    loadPlaces() {
      const params = {
        limit: 0,
        type: 'clinical',
      };
      return this.$http.get('/places', { params })
        .then(({ data }) => {
          this.places = data.items;
        })
        .catch(this.$toast.error);
    },
    apiGetInsurances(params) {
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => data);
    },
    selectAllStatuses(status) {
      this.statuses.forEach((item) => {
        item.selected = status;
      });
    },
    getParams() {
      const params = {
        limit: 0,
        offset: this.data.offset,
      };

      if (this.filters.insurancePlanId) {
        params.insurancePlanId = this.filters.insurancePlanId;
      }

      if (this.filters.professionalId) {
        params.professionalId = this.filters.professionalId;
      }

      if (this.filters.status) {
        params.status = this.filters.status;
      }

      if (this.filters.period) {
        params.period = this.filters.period;
      }

      if (this.filters.patient) {
        params.patient = this.filters.patient;
      }

      if (this.filters.scheduleType) {
        params.scheduleType = this.filters.scheduleType;
      }

      if (this.filters.startDate) {
        params.startDate = this.filters.startDate;
      }

      if (this.filters.endDate) {
        params.endDate = this.filters.endDate;
      }

      if (this.filters.otherProfessional) {
        params.otherProfessional = this.filters.otherProfessional;
      }

      if (this.filters.placeId) {
        params.placeId = this.filters.placeId;
      }

      params.statuses = this.statuses
        .filter(({ selected }) => selected)
        .map(({ id }) => id)
        .join(',');

      return params;
    },
    print(printType) {
      if (!this.canAccessPrintAttendance) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return;
      }

      this.printing = true;

      const params = this.getParams();
      params.printType = printType;

      if (printType === 'sheet') {
        this.$file
          .download('/reports/attendances', params, {
            method: 'POST',
            name: 'Relação de atendimentos.xlsx',
          })
          .catch(() => {})
          .then(() => {
            this.printing = false;
          });
      } else {
        this.$file
          .print('/reports/attendances', params, { method: 'POST' })
          .catch(() => {
          })
          .then(() => {
            this.printing = false;
          });
      }
    },
    orderByAccounts(orderBy) {
      if (this.orderBy.type === orderBy) this.orderBy.index *= -1; else this.orderBy.index = 1;
      this.orderBy.type = orderBy;

      if (orderBy === 'date') {
        this.data.items.sort((a, b) => {
          if (moment(a.startDate).format('YYYY-MM-DD')
            > moment(b.startDate).format('YYYY-MM-DD')) return this.orderBy.index;
          if (moment(a.startDate).format('YYYY-MM-DD')
            < moment(b.startDate).format('YYYY-MM-DD')) return this.orderBy.index * -1;
          if (moment(a.startDate).format('YYYY-MM-DD')
            === moment(b.startDate).format('YYYY-MM-DD')) {
            if (a.patientName > b.patientName) return 1;
            if (a.patientName < b.patientName) return -1;
          }
          return 0;
        });
      } else if (orderBy === 'patient') {
        this.data.items.sort((a, b) => {
          if (a.patientName > b.patientName) return this.orderBy.index;
          if (a.patientName < b.patientName) return this.orderBy.index * -1;
          if (a.patientName === b.patientName) {
            if (moment(a.startDate).format('YYYY-MM-DD')
              > moment(b.startDate).format('YYYY-MM-DD')) return 1;
            if (moment(a.startDate).format('YYYY-MM-DD')
              < moment(b.startDate).format('YYYY-MM-DD')) return -1;
          }
          return 0;
        });
      } else if (orderBy === 'insurance') {
        this.data.items.sort((a, b) => {
          if (a.insuranceCustomName > b.insuranceCustomName) return this.orderBy.index;
          if (a.insuranceCustomName < b.insuranceCustomName) return this.orderBy.index * -1;
          if (a.insuranceCustomName === b.insuranceCustomName) {
            if (a.patientName > b.patientName) return 1;
            if (a.patientName < b.patientName) return -1;
            if (a.patientName === b.patientName) {
              if (moment(a.startDate).format('YYYY-MM-DD')
                > moment(b.startDate).format('YYYY-MM-DD')) return 1;
              if (moment(a.startDate).format('YYYY-MM-DD')
                < moment(b.startDate).format('YYYY-MM-DD')) return -1;
            }
          }
          return 0;
        });
      } else if (orderBy === 'total') {
        this.data.items.sort((a, b) => {
          if (a.total > b.total) return this.orderBy.index;
          if (a.total < b.total) return this.orderBy.index * -1;
          if (a.total === b.total) {
            if (a.patientName > b.patientName) return 1;
            if (a.patientName < b.patientName) return -1;
            if (a.patientName === b.patientName) {
              if (moment(a.startDate).format('YYYY-MM-DD')
                > moment(b.startDate).format('YYYY-MM-DD')) return 1;
              if (moment(a.startDate).format('YYYY-MM-DD')
                < moment(b.startDate).format('YYYY-MM-DD')) return -1;
            }
          }
          return 0;
        });
      }
    },
    getOrderByIcon(orderBy) {
      if (orderBy === this.orderBy.type) {
        return this.orderBy.index === 1 ? 'sort-down' : 'sort-up';
      }
      return 'sort';
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";
@import '~assets/scss/mixins';

.billing-accounts-page {
  .attendance-summary {
    background-color: $gray-color-ultra-light;
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin: $layout-spacing-sm 0 $layout-spacing 0;
    padding: $layout-spacing;
  }
  .attendance-items-title {
    border-bottom: $border-color solid $border-width;
    background-color: $gray-color-ultra-light;
    margin: 0 calc($layout-spacing-sm / 5);
    padding: $layout-spacing 0;
  }
  .status-group {
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
  }
  .footer {
    text-align: right;
  }
}
</style>
