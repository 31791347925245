<template>
  <div class="page-container schedules-page">
    <portal to="page-name">Gerenciamento de agendas</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Gerenciamento de agendas</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-2 form-group">
            <select class="form-select" v-model="filter.type">
              <option value="">[Todas as agendas]</option>
              <option v-for="(text, value) in scheduleTypes"
                      :value="value" :key="value">{{ text }}</option>
            </select>
          </div>
          <div class="column form-group">
            <div class="input-group">
              <input type="text" id="filter-search" name="filter-search" class="form-input"
                     v-model="filter.search" maxlength="100"
                     placeholder="Localizar uma agenda" autocomplete="nope">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon" tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto">
            <button class="btn btn-primary btn-icon btn-icon-left"
                    @click="create">
              <fa-icon :icon="['fal', 'plus']"/> Nova agenda
            </button>
          </div>
        </div>
        <div class="loading mt-2" v-if="loading"></div>
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Agendas</p>
              <p class="empty-subtitle">
                Nenhuma agenda encontrada. Clique no botão abaixo para criar.
              </p>
              <button class="btn btn-primary btn-icon btn-icon-left"
                      @click="create">
                <fa-icon :icon="['fal', 'plus']"/> Nova agenda
              </button>
            </div>
          </template>
          <template v-else>
            <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th class="hide-sm" width="40px">#</th>
            <th>Nome</th>
            <th>Local</th>
            <th>Tipo</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in data.items" :key="i">
            <td class="hide-sm">{{ i + 1 }}</td>
            <td><router-link :to="editLink(item)">{{ item.name }}</router-link></td>
            <td>{{ item.place ? item.place.name : '--' }}</td>
            <td>{{ getScheduleType(item.type, scheduleTypes.elective) }}</td>
            <td class="text-right">
              <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip mr-1"
                      @click="edit(item)" data-tooltip="Editar">
                <fa-icon :icon="['fal', 'pencil']"></fa-icon>
              </button>
              <button class="btn btn-sm btn-action btn-icon btn-error"
                      :disabled="deleting" :class="{loading: deleting}"
                      @click="remove(item)">
                <fa-icon :icon="['fal', 'times']"></fa-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
          </template>
        </template>
      </div>
      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  types as scheduleTypes,
  getName as getScheduleType,
} from '@/data/schedule-types';
import { DELETE_SCHEDULE } from '@/data/actions/modules/clinical';

export default {
  data() {
    return {
      path: '/schedules',
      filter: {
        type: '',
        search: '',
      },
      debounceTimeout: null,
      loading: false,
      loadingMore: false,
      deleting: false,
      data: {
        items: [],
        hasMore: false,
        limit: 30,
        offset: 0,
      },
      scheduleTypes,
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => this.load(), 600);
      },
      deep: true,
    },
  },
  computed: {
    canAccessRemoveSchedule() {
      if (DELETE_SCHEDULE) {
        return this.$can(DELETE_SCHEDULE);
      }
      return true;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    getScheduleType,
    async loadMore() {
      this.data.offset += this.data.limit;
      this.load();
    },
    search() {
      this.data.offset = 0;
      this.load();
    },
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.search.trim().length > 0) {
        params.offset = 0;
        params.search = this.filter.search;
      }

      if (this.filter.type) {
        params.type = this.filter.type;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    create() {
      this.$router.push(`/clinical${this.path}/create`);
    },
    edit(item) {
      this.$router.push(`/clinical${this.path}/${item.id}/edit`);
    },
    editLink(item) {
      return `/clinical${this.path}/${item.id}/edit`;
    },
    remove(item) {
      if (!this.canAccessRemoveSchedule) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir esta agenda e seus horários?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.$http.delete(`${this.path}/${item.id}`)
                .catch(() => {})
                .then(() => {
                  close();
                  const idx = this.data.items.indexOf(item);
                  this.data.items.splice(idx, 1);
                  this.data.total = this.data.items.length;
                  this.deleting = false;
                });
            },
          },
        ],
      });
    },
    paginate({ offset }) {
      this.data.offset = offset;
      this.load();
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .schedules-page {
  }
</style>
